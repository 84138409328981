import {
  doLogin,
  doLoginWithSocialProvider,
  getUser,
  doLogout,
  doCheckPassword,
  doChangePassword,
  doCheckEmailExistence,
  doCheckEmailForResetPassword,
  doSendPasswordReset,
  doVerifyResetPasswordToken,
  doSendConfirmationCode,
  doVerifyPhoneNumber,
  doRegister,
  doSendDeleteCode,
  doConfirmDeleteCode,
  doSendCodeToPhone,
  doVerifyPhoneCode,
  doSendCodeToEmail,
  doVerifyEmailCode,
  doGetStates,
} from '@/api/auth';
import { isSuccessfulResponse } from '@/api/utilities';
import { useAuthStore } from '@/store-v2/auth';
import { useCreditStore } from '@/store-v2/credit';

import Vue from 'vue';

export default {
  namespaced: true,
  state: () => ({
    authToken: localStorage.getItem('authToken') ?? null,
    user: null,
    isUserAuditor: false,
    isPromoUser: false,
    isFluoroSafetyUser: false,
    guestHashSession: localStorage.getItem('acapediaGuestHashSession') ?? null,
  }),
  mutations: {
    setAuthToken(state, token) {
      const { setAuthToken } = useAuthStore();
      // set token in new pinia store
      setAuthToken(token);

      state.authToken = token;
      localStorage.setItem('authToken', state.authToken);
    },
    setUser(state, data) {
      // This is throwing errors in the vue devtools
      // TODO: migrate the credits api call to vue query
      const { setCreditsFromUser } = useCreditStore();
      // set credits in new pinia store
      setCreditsFromUser(data);

      state.user = data;
    },
    setTypeOfUser(state, data) {
      if (data.is_independent_auditor === 1) {
        state.isUserAuditor = true;
        return;
      }
      state.isUserAuditor = false;

      if (data.is_promo_user && data.acquisition_source === 'FluoroSafety') {
        state.isFluoroSafetyUser = true;
      } else if (data.is_promo_user) {
        state.isPromoUser = true;
      }
    },
    unsetAuthToken(state) {
      const { setAuthToken } = useAuthStore();
      // set token in new pinia store
      setAuthToken(null);

      state.authToken = null;
      localStorage.removeItem('authToken');
    },
    unsetUser(state) {
      state.user = null;
    },
    setGuestHashSession(state, hash) {
      state.guestHashSession = hash;
    },
  },
  actions: {
    async login(context, params) {
      const loginResult = await doLogin(params);
      Vue.prototype.$eventNotifier.setUser(loginResult.user);

      await context.dispatch('initializeSession', loginResult.token);
    },
    async setLocalStorageTokenFromUrlToken(context, params) {
      return context.dispatch('initializeSession', params.token);
    },
    async loginWithSocialProvider(context, params) {
      const loginResult = await doLoginWithSocialProvider(params);
      await context.dispatch('initializeSession', loginResult.token);
    },
    async initializeSession({ commit, dispatch }, token) {
      commit('setAuthToken', token);
      await dispatch('bootstrapUserData');
    },
    async bootstrapUserData({ dispatch, getters }) {
      if (!getters.authToken) {
        Vue.prototype.$eventNotifier.setUser();
        return;
      }

      const user = await dispatch('getUser');
      // In case of unauthenticated user, we don't fetch the following data
      // As they are breaking the app on refresh (a blank page)
      if (!user) return;

      return Promise.all([
        dispatch('profile/getProfile', null, { root: true }),
        dispatch('subscriptions/getUserSubscription', null, { root: true }),
        dispatch('profile/checkIfPaymentMethodWillExpire', null, { root: true }),
        dispatch('specialties/loadSpecialties', user, { root: true }),
      ]);
    },
    async getUser({ commit, getters }) {
      const { authToken } = getters;
      if (!authToken) return;

      try {
        const response = await getUser();

        if (isSuccessfulResponse(response)) {
          Vue.prototype.$eventNotifier.setUser(response.data);
          commit('setUser', response.data);
          commit('setTypeOfUser', response.data);

          return response.data;
        }
      } catch (error) {
        commit('unsetUser');
      }
    },
    async logout({ dispatch }) {
      await doLogout();
      Vue.prototype.$eventNotifier.unsetUser();
      dispatch('localLogout');
    },
    async localLogout({ commit, dispatch }) {
      commit('unsetUser');
      commit('unsetAuthToken');
      dispatch('profile/unsetProfile', null, { root: true });
      dispatch('profile/unsetPaymentWillExpire', null, { root: true });
      dispatch('subscriptions/unsetPlans', null, { root: true });
      dispatch('subscriptions/unsetPlanSelected', null, { root: true });

      dispatch(
        'specialties/updateSpecialtiesFromCustomizer',
        {
          specialties: [],
          subSpecialties: [],
        },
        { root: true },
      );

      dispatch('subscriptions/removeUserSubscription', {}, { root: true });
      localStorage.removeItem('acapedia_user_visited_articles');
      localStorage.removeItem('acapedia_taken_quizzes_by_guest_user');
      localStorage.removeItem('acapedia_guest_quizzes');
      localStorage.removeItem('acapediaGuestHashSession');
      localStorage.removeItem('acapedia_special_requirements_selected_state');
    },
    checkPassword(context, payload) {
      return doCheckPassword(payload);
    },
    changePassword(context, payload) {
      return doChangePassword(payload);
    },
    emailExists(context, payload) {
      return doCheckEmailExistence(payload);
    },
    emailExistsForResetPassword(context, payload) {
      return doCheckEmailForResetPassword(payload);
    },
    sendPasswordReset(contenxt, payload) {
      return doSendPasswordReset(payload);
    },
    verifyResetPasswordToken(context, payload) {
      return doVerifyResetPasswordToken(payload);
    },
    sendConfirmationCode(context, payload) {
      return doSendConfirmationCode(payload);
    },
    verifyPhoneNumber(context, payload) {
      return doVerifyPhoneNumber(payload);
    },
    async register(context, params) {
      const guestQuizzes = JSON.parse(localStorage.getItem('acapedia_guest_quizzes')) ?? [];
      if (guestQuizzes.length) {
        params = {
          ...params,
          guestQuizzes: guestQuizzes,
          userGuestHash: localStorage.getItem('acapediaGuestHashSession') ?? null,
        };
      }

      const response = await doRegister(params);

      if (isSuccessfulResponse(response)) {
        // Not ideal to access data.data, but
        // we wrapped the response in a data object because
        // we need extra info (metadata, like hashed information) for social providers
        // separate from the user object
        const responseBody = response.data.data;
        const { user, token } = responseBody;

        Vue.prototype.$eventNotifier.setUser(user);
        localStorage.removeItem('acapedia_guest_quizzes');

        context.commit('setUser', user);
        await context.dispatch('initializeSession', token);

        return responseBody;
      }
    },
    sendDeleteCode(context, payload) {
      return doSendDeleteCode(payload);
    },
    async confirmDeleteCode(context, payload) {
      const response = await doConfirmDeleteCode(payload);

      if (isSuccessfulResponse(response)) {
        context.commit('setUser', null);

        context.commit('setAuthToken', null);
      }

      return response;
    },
    sendCodeToPhone() {
      return doSendCodeToPhone();
    },
    verifyPhoneCode(context, payload) {
      return doVerifyPhoneCode(payload);
    },
    sendCodeToEmail() {
      return doSendCodeToEmail();
    },
    verifyEmailCode(context, payload) {
      return doVerifyEmailCode(payload);
    },
    getStates() {
      return doGetStates();
    },
  },
  getters: {
    currentUser: (state) => {
      return state.user;
    },
    isAuditor: (state) => {
      return state.isUserAuditor;
    },
    isLoggedInUser: (state) => {
      return !!state.authToken;
    },
    isPromoUser: (state) => {
      return state.isPromoUser;
    },
    isFluoroSafetyUser: (state) => {
      return state.isFluoroSafetyUser;
    },
    authToken: (state) => {
      return state.authToken;
    },
    guestHashSession: () => {
      // using only the state is not reactive for this purpose
      return localStorage.getItem('acapediaGuestHashSession') ?? null;
    },
    hasUnAwardedCredits: (state) => {
      return state.user?.unawardedCredits > 0;
    },
    isGuestUser: (state, getters) => {
      return !getters.isLoggedInUser;
    },
  },
};
