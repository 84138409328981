import VueGtag from 'vue-gtag';
import { isProductionEnv } from '@/util';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { notifyFailedPayment as notifyFailedPaymentOnSlack } from '@/api/slackNotifications';

export default {
  install(Vue) {
    Vue.use(VueGtag, {
      config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
        params: {
          send_page_view: false,
        },
      },
    });

    Vue.prototype.$eventNotifier = {
      notify(eventName, { eventCategory, eventLabel } = {}) {
        const gtagParams = {};

        if (eventCategory) {
          gtagParams.event_category = eventCategory;
        }

        if (eventLabel) {
          gtagParams.event_label = eventLabel;
        }

        Vue.prototype.$gtag.event(eventName, gtagParams);
      },
      purchase({ currency, transactionId, value, items }) {
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12777964546362446356-SA&client_type=gtag#purchase
        const gtagPurchaseParams = {
          currency: currency,
          transaction_id: transactionId,
          // The monetary value of the event.
          value: value,
          items: items.map((item) => ({
            id: item.id,
            item_name: item.itemName,
            item_category: item.itemCategory,
            quantity: item.quantity,
            price: item.price,
          })),
        };

        Vue.prototype.$gtag.purchase(gtagPurchaseParams);

        // We want to track our custom event as well
        this.notify('purchase_plan', {
          eventCategory: 'Purchase Plan',
          eventLabel: items[0].itemName,
        });
      },
      login({ method }) {
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12777964546362446356-SA&client_type=gtag#login
        Vue.prototype.$gtag.event('login', { method });
      },
      logout() {
        this.notify('logout', {
          eventCategory: 'Logout',
          eventLabel: 'User Logout',
        });
      },
      async register({ method, hashedEmail, hashedFirstName, hashedLastName, hashedPhoneNumber, hashedDateOfBirth }) {
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12777964546362446356-SA&client_type=gtag#sign_up
        Vue.prototype.$gtag.event('sign_up', { method });

        await this.trackRegisterConversionOnPlatforms({
          hashedEmail,
          hashedFirstName,
          hashedLastName,
          hashedPhoneNumber,
          hashedDateOfBirth,
        });
      },
      viewRegister() {
        this.trackViewRegisterConversionOnPlatforms();
      },
      selectItem({ itemListId, itemListName, item }) {
        const gtagParams = {
          item_list_id: itemListId,
          item_list_name: itemListName,
          items: [
            {
              item_id: item.itemId,
              item_name: item.itemName,
              item_category: item.itemCategory,
              currency: item.currency,
              price: item.price,
              quantity: item.quantity,
            },
          ],
        };

        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12777964546362446356-SA&client_type=gtag#select_item
        Vue.prototype.$gtag.event('select_item', gtagParams);
      },
      setUser({ id, email, currentPlanName } = {}) {
        const userProperties = {};

        if (id) {
          // https://www.analyticsmania.com/post/google-analytics-4-user-id
          userProperties.user_id = id;
        }

        if (email) {
          userProperties.user_email = email;
        }

        if (currentPlanName) {
          userProperties.user_current_plan = currentPlanName;
        }

        this.setBrowserSize(userProperties);

        Vue.prototype.$gtag.set({
          user_properties: userProperties,
        });
      },
      setBrowserSize(userProperties) {
        const width = window.innerWidth;
        const height = window.innerHeight;

        userProperties.user_browser_width = width;
        userProperties.user_browser_height = height;
        userProperties.user_browser_size = `${width}x${height}`;
      },
      async trackRegisterConversionOnPlatforms({
        hashedEmail,
        hashedFirstName,
        hashedLastName,
        hashedPhoneNumber,
        hashedDateOfBirth,
      }) {
        if (!isProductionEnv) {
          console.warn('"register" conversion was not tracked because we are not in production');
          return;
        }

        try {
          this.trackRegisterConversionOnGtag();
          this.trackRegisterConversionOnLinkedin();
          this.trackRegisterConversionOnTwitter();
          await this.trackRegisterConversionOnMeta({
            hashedEmail,
            hashedFirstName,
            hashedLastName,
            hashedPhoneNumber,
            hashedDateOfBirth,
          });
        } catch (error) {
          console.error('Error tracking signup conversion', error);
          Sentry.captureException(error);
        }
      },
      trackRegisterConversionOnGtag() {
        window.gtag_report_conversion();
        console.info('Gtag "register" conversion tracked');
      },
      trackRegisterConversionOnLinkedin() {
        window.lintrk('track', { conversion_id: 14927017 });
        console.info('LinkedIn "register" conversion tracked');
      },
      async trackRegisterConversionOnMeta({
        hashedEmail,
        hashedFirstName,
        hashedLastName,
        hashedPhoneNumber,
        hashedDateOfBirth,
      }) {
        const access_token = process.env.VUE_APP_FACEBOOK_CONVERSION_ACCESS_TOKEN;
        const api_version = 'v17.0';
        const pixel_id = process.env.VUE_APP_FACEBOOK_CONVERSION_PIXEL_ID;
        const url = `https://graph.facebook.com/${api_version}/${pixel_id}/events?access_token=${access_token}`;
        const unixTimestampInSeconds = Math.floor(Date.now() / 1000);

        const userData = {};

        if (hashedEmail) {
          userData.em = [hashedEmail];
        }

        if (hashedFirstName) {
          userData.fn = [hashedFirstName];
        }

        if (hashedLastName) {
          userData.ln = [hashedLastName];
        }

        if (hashedPhoneNumber) {
          userData.ph = [hashedPhoneNumber];
        }

        if (hashedDateOfBirth) {
          userData.db = [hashedDateOfBirth];
        }

        const requestData = {
          data: [
            {
              action_source: 'website',
              event_name: 'Complete registration',
              event_time: unixTimestampInSeconds,
              user_data: userData,
            },
          ],
        };

        await axios.post(url, requestData);
        console.info('Facebook "register" conversion tracked');
      },
      trackRegisterConversionOnTwitter() {
        window.twq('event', 'tw-ogs0r-ogs1g', {
          conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        });
        console.info('Twitter "register" conversion tracked');
      },
      trackViewRegisterConversionOnPlatforms() {
        if (!isProductionEnv) {
          console.warn('The "view register" conversion was not tracked because we are not in production');
          return;
        }

        this.trackViewRegisterConversionOnGtag();
        this.trackViewRegisterConversionOnLinkedin();
        this.trackViewRegisterConversionOnTwitter();
        this.trackViewRegisterConversionOnMeta();
      },
      trackViewRegisterConversionOnGtag() {
        window.gtag_report_view_register_conversion();
        console.info('Gtag "view register" conversion tracked');
      },
      trackViewRegisterConversionOnLinkedin() {
        window.lintrk('track', { conversion_id: 14291796 });
        console.info('LinkedIn "view register" conversion tracked');
      },
      trackViewRegisterConversionOnTwitter() {
        window.twq('event', 'tw-ogs0r-ogs13', {
          conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        });
        console.info('Twitter "view register" conversion tracked');
      },
      trackViewRegisterConversionOnMeta() {
        window.fbq('track', 'AddToWishlist');
        console.info('Facebook "view register" conversion tracked');
      },
      unsetUser() {
        Vue.prototype.$gtag.set({ user_properties: null });
      },
      async notifyFailedPayment(userEmail) {
        try {
          await notifyFailedPaymentOnSlack({
            userEmail: userEmail,
          });
        } catch (error) {
          console.error('Error sending failed payment notification', error);
        }
      },
    };
  },
};
